import React from 'react';
import styled from 'styled-components';

const RouteWrapper = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

interface RouteProps {
  children: React.ReactNode;
}

const Story: React.FC<RouteProps> = ({ children }: RouteProps) => {
  return <RouteWrapper>{children}</RouteWrapper>;
};

export default Story;
