import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';

const BackLink = styled(Link)`
  font-size: 24px;
  text-decoration: none;
  color: black;
  padding-left: 15px;
`;

const NavBar = styled.div`
  width: 100%;
  height: 36px;
  line-height: 36px;
`;

const Nav: React.FC = () => (
  <NavBar>
    <BackLink to="/">Kalenteriin</BackLink>
  </NavBar>
);
export default Nav;
