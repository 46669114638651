import React from 'react';
import { RouteComponentProps } from '@reach/router';
import RouteWrapper from '../components/route-wrapper';
import DoorGrid from '../containers/door-grid';
import Snowflakes from '../components/snowflakes';

const Index: React.FC<RouteComponentProps> = () => (
  <RouteWrapper>
    <Snowflakes />
    <DoorGrid />
  </RouteWrapper>
);

export default Index;
