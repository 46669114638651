const cancel = (): void => {
  window.speechSynthesis.cancel();
};

const tell = async (text: string): Promise<void> => {
  try {
    return new Promise<void>(resolve => {
      const speak = new SpeechSynthesisUtterance(text);
      const voices = window.speechSynthesis.getVoices();

      speak.lang = voices.find(v => v.lang === 'fi-FI') ? 'fi-FI' : 'ja-JP';
      speak.onend = (): void => {
        resolve();
      };
      window.speechSynthesis.speak(speak);

      console.log(speak);
    });
  } catch {
    //pass
  }
};

export default {
  cancel,
  tell,
};
