import React from 'react';
import { hot } from 'react-hot-loader/root';
import Router from './routes/router';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  body{
    background: linear-gradient(to bottom, #aa3a38, #2f7336);
  }
  #root {
    width: 100%;
    height: 100%;
  }
`;

const App: React.FC = () => {
  return (
    <>
      <Router />
      <GlobalStyles />
    </>
  );
};

// eslint-disable-next-line no-undef
export default process.env.NODE_ENV === 'development' ? hot(App) : App;
