import React from 'react';
import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';
import { Link } from '@reach/router';
import { Day } from '../containers/door-grid';

const bounceAnimation = keyframes`${bounce}`;

const DoorWrapper = styled.div`
  background: #e6dada;
  background: -webkit-linear-gradient(to bottom, #e6dada, #274046);
  background: linear-gradient(to bottom, #e6dada, #274046);
  width: 100%;
  font-size: 3rem;
  align-self: center;
  justify-self: center;
  width: 180px;
  height: 180px;
  border-radius: 90px;
  border: 5px white solid;
  text-align: center;
  line-height: 180px;
  @media screen and (max-height: 800px) {
    height: 120px;
    width: 120px;
    font-size: 2rem;
    line-height: 120px;
  }
  @media screen and (max-height: 600px) {
    height: 100px;
    width: 100px;
    font-size: 1.5rem;
    line-height: 100px;
  }
`;

const CurrentDoorWrapper = styled(Link)`
  animation: 1s ${bounceAnimation} infinite;
  background: #e6dada;
  background: -webkit-linear-gradient(to bottom, #e6dada, #274046);
  background: linear-gradient(to bottom, #e6dada, #274046);
  width: 100%;
  font-size: 3rem;
  align-self: center;
  justify-self: center;
  width: 180px;
  text-decoration: none;
  color: black;
  height: 180px;
  border-radius: 90px;
  border: 5px white solid;
  text-align: center;
  line-height: 180px;
  cursor: pointer;
  @media screen and (max-height: 800px) {
    height: 120px;
    width: 120px;
    font-size: 2rem;
    line-height: 120px;
  }
`;

interface DoorProps {
  day: Day;
}

const Door: React.FC<DoorProps> = ({ day }: DoorProps) => {
  const today = new Date();
  return +day.day <= today.getDate() ? (
    <CurrentDoorWrapper to={`/story/${day._id}`}>{day.day}</CurrentDoorWrapper>
  ) : (
    <DoorWrapper>{day.day}</DoorWrapper>
  );
};

export default Door;
