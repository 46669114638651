interface Query {
  [key: string]: string | number | string[];
}

// eslint-disable-next-line no-undef
const apiUrl = process.env.API_URL || 'https://jul-kalendar-200.tk/';

abstract class Service {
  constructor(public path: string) {}

  buildQuery = (query: Query): string => {
    const result = Object.entries(query).reduce((accu, curren) => {
      let value = curren[1];
      if (Array.isArray(value))
        value = value.reduce((acc, curre) => `${acc}${curren[0]}=${curre}&`, '');
      else value = `${curren[0]}=${curren[1]}&`;
      return `${value}${accu}`;
    }, '');
    return '?' + result;
  };

  get = async (id = '', query = {}): Promise<unknown> => {
    const response = await fetch(`${apiUrl}api/${this.path}/${id}${this.buildQuery(query)}`);
    const result = await response.json();
    return result;
  };
}
class DoorService extends Service {
  constructor() {
    super('doors');
  }
}

class StoryService extends Service {
  constructor() {
    super('stories');
  }
}

class StaticService {
  getUrl = (name: string): string => `${apiUrl}static/${name}`;
}

export const doorService = new DoorService();
export const storyService = new StoryService();
export const staticService = new StaticService();
