import React, { useEffect, useState } from 'react';
import { staticService, storyService } from '../lib/client';

import StoryTeller from '../lib/story-teller';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const ImageWrapper = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
`;

interface StoryContainerProps {
  id?: string;
  endCallback: () => void;
}

interface File {
  uniqueName: string;
}

interface Story {
  text: string;
  _id: string;
  file: File;
}

const StoryContainer: React.FC<StoryContainerProps> = ({
  id,
  endCallback,
}: StoryContainerProps) => {
  const [story, setStory] = useState<Story>();
  const [image, setImage] = useState<string>();

  useEffect(() => (): void => StoryTeller.cancel(), []);

  useEffect(() => {
    window.addEventListener('beforeunload', StoryTeller.cancel);
  }, []);

  useEffect(() => {
    const fetchStory = async (): Promise<void> => {
      const result: Story = (await storyService.get(id, {
        with: ['file', 'door'],
      })) as Story;

      const url = staticService.getUrl(result.file.uniqueName);

      setImage(url);
      setStory(result);
    };

    fetchStory();
  }, [id]);

  const readStory = async (): Promise<void> => {
    if (!story) return;
    console.log('readstory');
    const texts = story.text.split(',');
    for (const text of texts) await StoryTeller.tell(text);
    endCallback();
    console.log('read end');
  };

  return (
    <>
      <ContentWrapper>
        {image && story && <ImageWrapper onLoad={readStory} src={image} />}
      </ContentWrapper>
    </>
  );
};

export default StoryContainer;
