import React, { useEffect, useState } from 'react';

import Door from '../components/door';
import { doorService } from '../lib/client';
import styled from 'styled-components';

const DoorGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 25px;
  width: 100%;
  min-height: 100%;
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(8, 1fr);
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(12, 1fr);
  }
`;

export interface Day {
  _id: string;
  day: string;
}
const DoorGrid: React.FC = () => {
  const [days, setDays] = useState<Day[]>([]);

  useEffect(() => {
    const fetchDoors = async (): Promise<void> => {
      const result = (await doorService.get()) as Day[];
      setDays(result.sort((a: Day, b: Day): number => (+a.day > +b.day ? 1 : -1)));
    };
    fetchDoors();
  }, []);

  if (days.length === 0) return <div />;
  return (
    <DoorGridWrapper>
      {days.map((day: Day) => (
        <Door key={`door-${day._id}`} day={day} />
      ))}
    </DoorGridWrapper>
  );
};

export default DoorGrid;
