import React from 'react';
import { Router } from '@reach/router';
import styled from 'styled-components';
import Index from './index';
import Story from './story';

const RouterWrapper = styled(Router)`
  width: 100%;
  height: 100%;
`;

const WebRouter: React.FC = () => (
  <RouterWrapper>
    <Index path="/" />
    <Story path="/story/:id" />
  </RouterWrapper>
);

export default WebRouter;
