import React, { useEffect, useState } from 'react';

import NavBar from '../components/nav-bar';
import { RouteComponentProps } from '@reach/router';
import RouteWrapper from '../components/route-wrapper';
import StoryContainer from '../containers/story-container';
import { doorService } from '../lib/client';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80%;
  margin: auto;
`;

const ButtonWrapper = styled.button`
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
`;

interface StoryProps extends RouteComponentProps {
  id?: string;
}

interface Door {
  day: number;
  stories: Story[];
}

interface File {
  uniqueName: string;
}
interface Story {
  text: string;
  createdAt: string;
  _id: string;
  file: File;
}

const Story: React.FC<StoryProps> = ({ id }: StoryProps) => {
  const [start, setStart] = useState(false);
  const [door, setDoor] = useState<Door>();
  const [completed, setCompleted] = useState(false);
  const [currentParagraph, setCurrentParagraph] = useState<number>(0);
  const [stories, setStories] = useState<Story[]>();

  useEffect(() => {
    const fetchStories = async (): Promise<void> => {
      const result = (await doorService.get(id, { with: 'stories' })) as Door;
      setDoor(result);
      const resStories = result ? result.stories : [];
      const raw = resStories.sort((a: Story, b: Story): number =>
        a && b && a.createdAt > b.createdAt ? 1 : -1,
      );
      setStories(raw);
    };
    fetchStories();
  }, [id]);

  const handleNewParagraph = (): void => {
    console.log(stories);
    if (!stories) return;
    console.log(currentParagraph);
    if (!stories[currentParagraph + 1]) {
      setStart(false);
      setCurrentParagraph(0);
      setCompleted(true);
      return;
    }
    setCurrentParagraph(currentParagraph + 1);
  };

  if (!door || !stories)
    return (
      <RouteWrapper>
        <NavBar />
        Ladataan
      </RouteWrapper>
    );

  if (door && stories.length === 0)
    return (
      <RouteWrapper>
        <NavBar />
        <ContentWrapper>Odota/oletko liian aikaisessa</ContentWrapper>
      </RouteWrapper>
    );

  return (
    <RouteWrapper>
      <NavBar />
      <ContentWrapper>
        {start && stories.length && (
          <StoryContainer id={stories[currentParagraph]._id} endCallback={handleNewParagraph} />
        )}
        {!start && (
          <ButtonWrapper onClick={(): void => setStart(true)}>
            {completed ? `Toista Luukku ${door.day} uudestaan` : `Aloita Luukku ${door.day}`}
          </ButtonWrapper>
        )}
      </ContentWrapper>
    </RouteWrapper>
  );
};

export default Story;
